import NextLink from 'next/link'
import FooterLinkCol from '../molecules/FooterLinkCol'
import FooterSocials from '../molecules/FooterSocials'
import { attributes } from '../content/footer.md'
import { useLocale } from '../hooks/useLocale'
import {
  FooterContents,
  FooterContents_FirstCategory,
} from '../content/types.d'
import Link from '@/atoms/Link'
import FooterContactInformation from '@/molecules/FooterContactInformation'
import Image from 'next-export-optimize-images/image'
import { Fragment } from 'react'

const Footer = () => {
  const { translations } = useLocale<FooterContents>(attributes)

  const categories: FooterContents_FirstCategory[] = [
    translations.first_cat,
    translations.second_cat,
    translations.third_cat,
  ]
  const currentYear = new Date(Date.now()).getFullYear()

  return (
    <footer className="py-12 px-6 md:px-8  leading-loose text-black bg-white xl:py-16 black:bg-black black:text-white light:bg-gray-700 lg:px-container-paddingy flex justify-center">
      <div className="flex flex-col justify-between gap-20 max-w-6xl w-full">
        <div className="flex flex-col md:flex-row w-full justify-between gap-10">
          <section className="w-fit flex flex-col md:flex-row gap-10 md:gap-12 justify-between">
            {categories.map((category) => (
              <FooterLinkCol
                key={category.colname}
                title={category.colname}
                className="text-sm gap-1 md:gap-3"
              >
                {category.links.map((link, i) => (
                  <Link indent="off" href={link.href} key={i}>
                    {link.text}
                  </Link>
                ))}
              </FooterLinkCol>
            ))}
          </section>
          <div className="flex flex-col gap-12 lg:pr-16">
            <FooterContactInformation />
            <FooterSocials />
          </div>
        </div>
        <div className="flex gap-6 flex-col">
          <NextLink
            href={translations.bottom_info.iso_certificate_href}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              src={'/assets/iso-27001-2022-wit.png'}
              className="-ml-3"
              alt="ISO 27001"
              width={240}
              height={63}
            />
          </NextLink>
          <div className="flex flex-col-reverse md:flex-col gap-6 md:gap-y-0">
            <div className="flex flex-col md:flex-row justify-between gap-6">
              <section className="w-full flex flex-col-reverse md:flex-row md:flex-wrap gap-6 md:gap-y-0 text-xs text-gray-750">
                <span className="text-white/60">{`© ${currentYear} ${translations.contact.company_name}`}</span>
              </section>
              <section className="flex flex-1 text-xs gap-6 whitespace-nowrap w-full text-white/60 grow-0">
                <span>{translations.bottom_info.vat}</span>
                <span>{translations.bottom_info.kvk}</span>
              </section>
            </div>
            <section className="w-full flex flex-col md:flex-row md:flex-wrap gap-6 md:gap-y-0 text-xs text-gray-750">
              {translations.bottom_info.links.map((item, idx) => (
                <Fragment key={item.href}>
                  {idx > 0 && <span className="hidden md:inline">•</span>}
                  <Link indent="off" variant="gray" icon="off" href={item.href}>
                    {item.text}
                  </Link>
                </Fragment>
              ))}
            </section>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
