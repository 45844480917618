import NextLink from 'next/link'
import NavItem from '../atoms/NavItem'
import Button from '@/atoms/Button'
import { useLocale } from '@/hooks/useLocale'
import {
  HeaderMenu,
  HeaderMenu_NavigationMenu,
  HeaderMenu_NavigationMenu_SubitemsOnLeft,
} from '@/content/types'
import { attributes } from '../content/header.md'
import LanguageSwitch from './LanguageSwitch'
import QuoteLink from './QuoteLink'
import ImageCardLink from './ImageCardLink'
import NavDropdown from '@/atoms/NavDropdown'

type Props = {
  show?: 'always' | 'sm' | 'md' | 'lg' | 'xl'
  className?: string
}

const NavItems = ({ show = 'always', className = '' }: Props) => {
  const { translations } = useLocale<HeaderMenu>(attributes)

  // hopefully this tricks tailwind into including these classes, as they
  // are all the possible classes that can result from the statement below
  /*eslint-disable */
  const _includeTailwindClasses = 'sm:flex md:flex lg:flex xl:flex'

  const cls = show !== 'always' ? `hidden ${show}:flex` : 'flex'

  const subitemElement = (
    subitem: HeaderMenu_NavigationMenu_SubitemsOnLeft,
  ) => {
    if (subitem.description)
      return (
        <QuoteLink
          href={subitem.href}
          title={subitem.text}
          subtitle={subitem.description}
          key={subitem.href}
        />
      )
    if (subitem.svg)
      return (
        <ImageCardLink
          href={subitem.href}
          title={subitem.text}
          svg={subitem.svg}
          key={subitem.href}
        />
      )
  }

  const dropdownElement = (item: HeaderMenu_NavigationMenu) => (
    <NavDropdown text={item.text} to={item.href} key={item.href}>
      <div className="grid lg:grid-cols-2 gap-6 lg:gap-16 items-center">
        <div className="grid gap-6 lg:gap-8 lg:mr-16">
          {item.subitems_left.map((subitem) => subitemElement(subitem))}
        </div>
        <div className="grid lg:grid-cols-2 gap-6 lg:gap-8">
          {item.subitems_right.map((subitem) => subitemElement(subitem))}
        </div>
      </div>
    </NavDropdown>
  )

  const linkElement = (item: HeaderMenu_NavigationMenu) => (
    <NavItem to={item.href} key={item.href}>
      {item.text}
    </NavItem>
  )

  return (
    <div
      className={`items-center ${cls} ${className} flex-1 justify-evenly h-full`}
    >
      {translations.navigation_menu.map((item) => {
        return item.type === 'dropdown'
          ? dropdownElement(item)
          : linkElement(item)
      })}
      <div className="flex gap-2">
        <NextLink href={translations.contact.href}>
          <Button className="h-12">{translations.contact.text}</Button>
        </NextLink>
        <LanguageSwitch />
      </div>
    </div>
  )
}

export default NavItems
